<template>
  <page-title-component title="Create Google Campaign"></page-title-component>

  <div class="row" v-if="!settings || !settings.google_user_id || !settings.google_ads_scope">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        <strong><i class="fas fa-exclamation-triangle"></i> Please log In Google and approve all permissions.</strong>
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-lg-12 layout-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area" id="google-wizard-template">
          <div class="underline-content">
            <ul class="nav nav-tabs mb-3 justify-content-between" role="tablist">
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 1}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-brands fa-product-hunt"></i> Product
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 2}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-circle-info"></i> Information
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 3}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-magnifying-glass"></i> Amazon Search Terms (Coming Soon)
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 4}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-brands fa-google"></i> Google Keywords
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 5}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-heading"></i> Headlines
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 6}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-check"></i> Review
                </a>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4 col-12" v-if="currentStep !== 6">
              <left-side />
            </div>

            <div :class="currentStep === 6 ? 'col-12 col-md-12' : 'col-12 col-md-8'">
              <component :is="'Step' + currentStep"
                         @changeStep="changeStepTo"
                         :errors="allErrors"
                         :formData="form"></component>
              <hr>

              <div class="row">
                <div class="col-6">
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-outline-default mr-4"
                          @click="currentStep--"
                          :disabled="currentStep === 1">Previous</button>

                  <button class="btn btn-primary"
                          :disabled="!canGoNext"
                          v-if="currentStep !== 6"
                          @click="currentStep++">Next</button>

                  <button class="btn btn-primary"
                          @click="submit"
                          v-else>Launch Ad</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import LeftSide from './LeftSide';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import {mapGetters} from "vuex";
import useVuelidate from "@vuelidate/core";
import {numeric, required} from "@vuelidate/validators";

export default {
  components: {PageTitleComponent, LeftSide, Step1, Step2, Step3, Step4, Step5, Step6},
  data() {
    return {
      currentStep: 1,
      form: {
        asin: '',
        no_end_date: false,
        ad_account_id: '',
        name: '',
        budget: '',
        max_cpc: '',
        start_time: window.moment().startOf("hour").format('YYYYMMDD'),
        end_time: window. moment().startOf("hour").add(32, "hour").format('YYYYMMDD'),
        search_term: [],
        link: '',
        image: '',
        headingInput: [
          {
            value: '',
            placeholder: 'Product name (required)',
          },
          {
            value: '',
            placeholder: 'Feature/Benefit e.g  “Lightweight and portable”, “2.5mm thin”, “400+ 5-star reviews on Amazon”, “Free shipping with Prime” (required)',
          },
          {
            value: '',
            placeholder: 'Feature/Benefit e.g  “Lightweight and portable”, “2.5mm thin”, “400+ 5-star reviews on Amazon”, “Free shipping with Prime” (required)',
          }
        ],
        descriptionInput:[
          {
            value: '',
            placeholder: 'Product Description - additional details/benefts e.g "High quality grip material that always stays on" (required) ',
          },
          {
            value: '',
            placeholder: 'Product Description - additional details/benefts, call to action e.g "Get yours today 30% off" (required)',
          }
        ],
        status: 'PAUSED',
        criteria_id: '',
        amazon_profile: '',
        keywords: []
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    if (this.currentStep === 2) {
      return {
        form: {
          ad_account_id: {required},
          amazon_profile: { required },
          name: { required },
          budget: { required, numeric },
          max_cpc: { numeric },
          start_time: { required },
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      wizardLoading: 'googleWizard/getWizardLoading',
      amazonProduct: 'googleWizard/getAmazonProduct'
    }),
    isStep5Valid() {
      // Check heading
      const requiredHeadingCount = 3;

      for(let i=0; i < requiredHeadingCount; i++) {
        if (this.form.headingInput[i].value === undefined || this.form.headingInput[i].value === '')
          return false;
      }

      if (this.checkIfDuplicateExists(this.form.headingInput.map(h => h.value)))
        return false;

      // Check description
      const requiredDescriptionCount = 2;

      for(let i=0; i < requiredDescriptionCount; i++) {
        if (this.form.descriptionInput[i].value === undefined || this.form.descriptionInput[i].value === '')
          return false;
      }

      return !this.checkIfDuplicateExists(this.form.descriptionInput.map(d => d.value));
    },
    canGoNext() {
      if (this.currentStep === 1) {
        return !!this.amazonProduct;
      } else if (this.currentStep === 2)
        return !this.v$.form.$invalid 
      else if (this.currentStep === 3)
        return this.form.search_term.length && this.form.search_term[0] !== undefined && this.form.search_term[0] !== '';
      else if (this.currentStep === 4)
        return this.form.keywords.length >= 5
      else if (this.currentStep === 5)
        return this.isStep5Valid;
      else
        return true;
    }
  },
  watch: {
    wizardLoading(status) {
      if (status)
        this.blockUI('google-wizard-template');
      else
        this.unblockUI('google-wizard-template');
    }
  },
  mounted() {
    this.initSteps();
  },
  methods: {
    changeStepTo(step) {
      this.currentStep = step;
    },
    initSteps() {
      window.$("#icon-text-wizard").steps({
        headerTag: "h3",
        bodyTag: "section",
        transitionEffect: "slideLeft",
        autoFocus: false,
        titleTemplate: '#title#',
        cssClass: 'ico-text wizard'
      });
    },
    submit() {
      this.allErrors = null;
      this.$store.commit('googleWizard/setWizardLoading', true);

      this.axios.post('/google/create-campaign', this.form)
        .then(() => {
          this.$store.commit('googleWizard/setAmazonProduct', null);
          this.$router.push({name: 'dashboard'});
        })
        .catch((err) => {
          if (err.response.status !== 422) {
            if ("data" in err.response) {
              let e = JSON.parse(JSON.stringify(JSON.parse(err.response.data.message)));

              if ("details" in e && e.details.length) {
                this.showFailMsg(e.details[0].errors[0].message);
              } else {
                this.showFailMsg("Something went wrong!");
              }
            } else {
              this.showFailMsg("Something went wrong!");
            }
          } else
            this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.$store.commit('googleWizard/setWizardLoading', false);
        });
    }
  }
}
</script>