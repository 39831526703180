<template>
  <h6 class="text-black font-weight-bold mb-4 mt-4">Enter the product ASIN you would like to create a Google Ads campaign for:</h6>

  <div class="row">
    <!-- <div class="col-6">
      <select class="form-control">
        <option>United States (amazon.com)</option>
      </select>
    </div> -->
    <div class="col-4">
      <input type="text"
             v-model="form.asin"
             @keyup="getAmazonProduct($event)"
             class="form-control"
             placeholder="Enter 10 letters ASIN (e.g. B0S60VG188)">
    </div>
  </div>

  <template v-if="amazonProduct">
    <div class="row mt-4">
      <div class="col-md-6 col-12">
        <h6 class="text-black font-weight-bold">Amazon Domain</h6>
        amazon.com
      </div>
      <div class="col-md-6 col-12">
        <h6 class="text-black font-weight-bold">Amazon Product ASIN</h6>
        {{ amazonProduct.asin }}
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <h6 class="text-black font-weight-bold">Product URL</h6>
        <a class="text-info"
           :href="amazonProduct.url"
           target="_blank">{{ amazonProduct.url }}</a>
      </div>

    </div>
    <hr>
    <div class="row">
      <div class="col-4">
        <h6 class="text-black font-weight-bold">Rating</h6>
        {{ amazonProduct.reviews.rating }}
      </div>
      <div class="col-4">
        <h6 class="text-black font-weight-bold">Price</h6>
        {{ amazonProduct.price.symbol+amazonProduct.price.current_price }}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <h6 class="text-black font-weight-bold">Product Title</h6>
        <p class="tab-content mt-2">{{ amazonProduct.title }}</p>
      </div>
    </div>
  </template>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
    
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct'
    })
  },
  methods: {
    getAmazonProduct() {
      if(this.form.asin.length == 10) {
        this.$store.dispatch('googleWizard/getAmazonProduct', this.form.asin)
          .then(() => {
            this.form.link = this.amazonProduct.url;
          }).catch((e) => {
            if(e.status === 500) {
              this.showFailMsg("This ASIN doesn't exist");
            }
          })
      }
    }
  }
}
</script>

<style scoped>

.tab-header h3 {
  color: #3b3f5c;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.tab-content{
  color: #000000;
    font-size: 16px;
    margin-top: 20px;
    line-height: 30px;
}
</style>