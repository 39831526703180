<template>
  <page-title-component title="Create Facebook Campaign"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <div>
        <form @submit.prevent="submit">
          <div  class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="select-ad-account">Ad Account</label>
                    <select class="form-control" :class="['form-select', {'is-invalid': errorFor('ad_account_id')}]"
                            @change="getCustomAudiences"
                            :disabled="loading"
                            id="select-ad-account"
                            v-model="form.ad_account_id">
                      <option value="">Select Ad Account</option>
                      <option :value="account.id"
                              v-for="account in adAccounts"
                              :key="'ad_account_'+account.id">{{ account.name + (account.end_advertiser_name ? (' (' + account.end_advertiser_name + ')') : '') }}</option>
                    </select>
                    <v-errors :errors="errorFor('ad_account_id')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="cname">Campaign Name</label>
                    <input :class="['form-control', {'is-invalid': errorFor('name')}]"
                          :disabled="loading"
                          type="text"
                          id="cname"
                          v-model="form.name">
                    <v-errors :errors="errorFor('name')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="daily-budget">Daily Budget</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon2">
                          {{ currentCurrency ? currentCurrency : 'USD' }}
                        </span>
                      </div>
                      <input :class="{'is-invalid': errorFor('daily_budget')}"
                            :disabled="loading"
                            type="text"
                            id="daily-budget"
                            class="form-control"
                            v-model="form.daily_budget">
                    </div>
                    <v-errors :errors="errorFor('daily_budget')" />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="campaignPeriod">Campaign Period</label>

                    <div class="input-group">
                      <input type="text" class="form-control" id="campaignPeriod">
                      <div class="input-group-append"><span class="input-group-text" id="basic-addon2"><i class="flaticon-calendar"></i></span></div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 ms-auto">
                  <div class="form-group mt-2">
                    <label>&nbsp;</label>
                    <div class="custom-control custom-checkbox checkbox-primary">
                      <input type="checkbox"
                            v-model="form.no_end_date"
                            class="custom-control-input"
                            id="basicChk2"
                            @change="changeContinue($event)">
                      <label class="custom-control-label" for="basicChk2">No end date</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="page">Page</label>
                    <select :class="['form-select', {'is-invalid': errorFor('page')}]"
                            :disabled="loading"
                            @change="loadInstagramAccounts"
                            class="form-control"
                            id="page"
                            v-model="form.page">
                      <option value="">Select Page</option>
                      <option :value="page.id"
                              v-for="page in pages"
                              :key="'page_'+page.id">{{ page.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('page')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="instagram">Instagram</label>
                    <select :class="['form-select', {'is-invalid': errorFor('instagram_id')}]"
                            :disabled="loading"
                            id="instagram"
                            class="form-control"
                            v-model="form.instagram_id">
                      <option value="">Select Instagram Account</option>
                      <option :value="insta.id"
                              v-for="insta in instagramAccounts"
                              :key="'insta_'+insta.id">{{ insta.username }}</option>
                    </select>
                    <v-errors :errors="errorFor('instagram_id')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="asin">ASIN</label>
                    <input :class="['form-control', {'is-invalid': errorFor('link')}]"
                          :disabled="loading"
                          type="text"
                          v-model="form.asin"
                          id="asin"
                          @keyup="changeProductLink($event.target.value)">
                    <v-errors :errors="errorFor('link')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="ad-name">Ad Name</label>
                    <input :class="['form-control', {'is-invalid': errorFor('ad_name')}]"
                          :disabled="loading"
                          type="text"
                          id="ad-name"
                          v-model="form.ad_name">
                    <v-errors :errors="errorFor('ad_name')" />
                  </div>
                </div>
              </div>

              <div class="row" v-if="amazonProduct">
                <div class="col-md-1 mb-3">
                  <img class="img-thumbnail" :src="amazonProduct.image">
                </div>
                <div class="col-md-5">
                  <h6>{{ form.headline }}</h6>
                  <p>{{ form.primary_text }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label :class="{'text-danger': form.headline.length > 255}" for="headline">Headline ({{ form.headline.length }}/255)</label>
                    <input :class="['form-control', {'is-invalid': errorFor('headline')}]"
                          :disabled="loading"
                          id="headline"
                          v-model="form.headline" />
                    <v-errors :errors="errorFor('headline')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label :class="{'text-danger': form.description.length > 10000}" for="description">Description ({{ form.description.length }}/10000)</label>
                    <textarea :class="['form-control', {'is-invalid': errorFor('description')}]"
                              :disabled="loading"
                              rows="3"
                              id="description"
                              v-model="form.description"></textarea>
                    <v-errors :errors="errorFor('description')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="custom-image">
                      Custom Image/Video
                      <span class="text-muted"
                            title="&#x2022; The tallest supported aspect ratio for images without links and videos on mobile feed is vertical (4:5). &#xA;&#xA;&#x2022; Images used for in-stream ads cannot be in portrait format. &#xA;&#xA;&#x2022; Minimum image size: 500 x 262 pixels &#xA;&#xA;&#x2022; Images smaller than 600 x 600 may be displayed as a small image tile on high-resolution devices.">
                        <i class="fas fa-info-circle"></i>
                      </span>
                    </label>

                    <input :class="['form-control', {'is-invalid': errorFor('custom_asset')}]"
                          :disabled="loading"
                          id="custom-image"
                          @input="form.custom_asset = $event.target.files[0]"
                          type="file">
                    <v-errors :errors="errorFor('custom_asset')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label :class="{'text-danger': form.primary_text.length > 125}" for="primaryText">Primary Text ({{ form.primary_text.length }}/125)</label>

                    <input :class="['form-control', {'is-invalid': errorFor('primary_text')}]"
                          type="text"
                          :disabled="loading"
                          id="primaryText"
                          v-model="form.primary_text">
                    <v-errors :errors="errorFor('primary_text')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Amazon Account</label>
                    <select :class="['form-control', {'is-invalid': errorFor('amazon_profile')}]"
                            :disabled="loading"
                            v-model="form.amazon_profile">
                      <option value="">Select Amazon Profile</option>
                      <option :value="account.profileId"
                              v-for="account in amazonAdAccounts"
                              :key="'amazon_account_'+account.profileId">{{ account.accountInfo.name }} ({{ account.currencyCode }})</option>
                    </select>
                    <v-errors :errors="errorFor('amazon_profile')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Campaign Status</label>

                    <select :class="['form-control', {'is-invalid': errorFor('status')}]"
                            :disabled="loading"
                            v-model="form.status">
                      <option value="PAUSED">PAUSED</option>
                      <option value="ACTIVE">ACTIVE</option>
                    </select>
                    <v-errors :errors="errorFor('status')" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Custom Audience</label>

                    <select :class="['form-control', {'is-invalid': errorFor('custom_audience_id')}]"
                            :disabled="loading"
                            id="custom-audience-select"
                            v-model="form.custom_audience_id">
                      <option value="">Select Custom Audience</option>
                      <option :value="audience.id"
                              v-for="audience in customAudiences"
                              :key="'audience_'+audience.id">{{ audience.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('custom_audience_id')" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <a class="btn btn-success form-control"
                      target="_blank"
                      :href="'#'">
                      <i class="fas fa-plus"></i> Create Audience
                    </a>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-primary form-control"
                            :disabled="loadingCustomAudience"
                            @click.prevent="getCustomAudiences">
                      <i class="fas fa-redo"></i> Refresh Audiences
                    </button>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Campaign Objective</label>

                    <select :class="['form-control', {'is-invalid': errorFor('ad_type')}]"
                            v-model="form.campaign_objective"
                            :disabled="loading">
                      <option value="BRAND_AWARENESS">Awareness</option>
                      <option value="LINK_CLICKS">Traffic</option>
                      <option value="POST_ENGAGEMENT">Engagement</option>
                      <option value="CONVERSIONS">Sales</option>
                    </select>
                    <v-errors :errors="errorFor('ad_type')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12 text-right">
              <button type="submit"
                      class="btn btn-info mr-1"
                      @click.prevent="preview"
                      :disabled="v$.form.$invalid"
                      v-if="!loading"><i class="fas fa-eye"></i> Preview</button>
              <!-- <button type="submit"
                      class="btn btn-success"
                      @click.prevent="preview"
                      :disabled="v$.form.$invalid"
                      v-if="!loading"><i class="fas fa-save"></i> Create Campaign</button> -->

              <button class="btn btn-success" type="button" disabled v-else>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <facebook-preview-modal @create="submit"
                          ref="fbPreviewModal" />
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'
import PageTitleComponent from "@/components/PageTitleComponent";
import {mapGetters} from "vuex";
import FacebookPreviewModal from "@/views/create_campaign/FacebookPreviewModal";

export default {
  components: {PageTitleComponent, FacebookPreviewModal},
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      form: {
        ad_account_id: { required },
        name: { required },
        daily_budget: { required, numeric },
        start_time: { required },
        page: { required },
        primary_text: { required },
        campaign_objective: { required },
        link: { required },
        headline: { required },
        ad_name: { required },
        status: { required },
        amazon_profile: { required },
      },
    }
  },
  data() {
    return {
      adAccounts: [],
      customAudiences: [],
      amazonProduct: null,
      pages: [],
      instagramAccounts: [],
      loadingCustomAudience: false,
      form: {
        targeting: null,
        asin: '',
        no_end_date: false,
        campaign_objective: 'LINK_CLICKS',
        ad_account_id: '',
        instagram_id: '',
        custom_audience_id: '',
        custom_asset: '',
        name: '',
        // ad_set_name: '',
        bid_amount: '',
        daily_budget: '',
        start_time: window.moment().startOf("hour").format(),
        end_time: window. moment().startOf("hour").add(32, "hour").format(),
        page: '',
        // creative_name: '',
        link: '',
        image: '',
        headline: '',
        description: '',
        primary_text: '',
        ad_name: '',
        status: 'PAUSED',
        amazon_profile: '',
      }
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
    currentCurrency() {
      if (this.form.ad_account_id !== '')
        return this.adAccounts.find(account => account.id === this.form.ad_account_id).currency;

      return '';
    }
  },
  created() {
    this.axios.get('/facebook/ad-accounts')
      .then(response => {
        this.adAccounts = response.data.data;
      })

    this.axios.get('/facebook/pages')
      .then(response => {
        this.pages = response.data.data;
      })
  },
  mounted() {
    this.dateRangePickerInit(false);
  },
  methods: {
    initSelect2(id, payload) {
      let self = this;

      window.$('#' + id).select2();

      window.$('#' + id).on('change', function () {
        let val = window.$('#' + id).select2("val");

        if (val && self.customAudiences.find(a => a.id == val).targeting !== undefined)
          self.form.targeting = JSON.stringify(self.customAudiences.find(a => a.id == val).targeting);
        else
          self.form.targeting = null;

        self.form[payload] = window.$('#' + id).select2("val");
      });
    },
    loadInstagramAccounts() {
      this.instagramAccounts = [];
      this.form.instagram_id = '';

      if (this.form.page !== '') {
        let page = this.pages.find(page => page.id === this.form.page);

        this.axios.get('/facebook/instagram', {
            params: {
              page_id: page.id,
              page_access_token: page.access_token
            }
          })
          .then(res => {
            this.instagramAccounts = res.data.data
          })
      }
    },
    changeContinue(event) {
      this.dateRangePickerInit(event.target.checked);
    },
    dateRangePickerInit(single) {
      let self = this;

      if (single) {
        window.$("#campaignPeriod").daterangepicker({
            singleDatePicker: true,
            startDate: window.moment().startOf("hour"),
            locale: {
              format: 'M/DD hh:mm A'
            },
          },
          function (t) {
            self.form.start_time = t.format();
          }
        )
      } else {
        window.$("#campaignPeriod").daterangepicker({
            startDate: window.moment().startOf("hour"),
            endDate: window. moment().startOf("hour").add(32, "hour"),
            locale: {
              format: 'M/DD hh:mm A'
            },
          },
          function (t, a) {
            self.form.start_time = t.format();
            self.form.end_time = a.format();
          }
        )
      }
    },
    getCustomAudiences() {
      this.customAudiences = [];
      this.form.custom_audience_id = '';


      if (this.form.ad_account_id !== "") {
        this.loadingCustomAudience = true;
        this.axios.get('/facebook/custom-audiences/' + this.form.ad_account_id)
          .then(response => {
            this.customAudiences = response.data.data;

            setTimeout(() => {
              this.initSelect2('custom-audience-select', 'custom_audience_id')
            }, 500);
          }).finally(() => {
          this.loadingCustomAudience = false;
        })
      }
    },
    changeProductLink(asin) {
      this.amazonProduct = null
      this.form.link = '';

      if (asin.length === 10) {
        let url = "https://www.amazon.com/dp/" + asin

        this.form.link = url;

        this.axios.get('https://api.linkpreview.net/?key=936f2910d86d01e8064d00b12d3a22ed&q=' + url)
          .then((response) => {
            this.amazonProduct = response.data
            this.form.headline = this.amazonProduct.title;
            this.form.image = this.amazonProduct.image;
            this.form.primary_text = this.amazonProduct.description;
          })
      }
    },
    preview() {
      this.sendRequest('facebook/preview-campaign', true);
    },
    submit() {
      this.sendRequest('/facebook/create-campaign');
    },
    sendRequest(url, preview = false) {
      this.allErrors = null;

      let formData = new FormData()

      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key] ? this.form[key] : '');
      })

      if (preview) {
        this.$store.commit('fbAds/setAdForm', this.form)
        this.$refs.fbPreviewModal.show(this.pages, this.instagramAccounts);
      } else {
        this.loading = true;
        this.axios.post(url, formData)
          .then(() => {
            this.$router.push({name: 'dashboard'});
          })
          .catch((err) => {
            if (err.response.status === 500 && "data" in err.response) {
              this.showFailMsg(err.response.data.message)
            } else if (err.response.status === 422) {
              this.allErrors = err.response.data.errors;
            } else {
              this.showFailMsg("Something went wrong!");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
}
</script>