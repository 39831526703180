<template>
  <h6 class="text-black font-weight-bold mb-0 title-line-height mt-3 mb-2">Enter keywords for your Google Ad. Google recommends 10 to 20</h6>

  <div class="mt-4">
    <div class="row mb-2">
      <div class="col-12">
        <div class="badge badge-dark mr-2 mb-2"
              v-for="(key, i) in form.keywords"
              :key="'key_'+i">
          {{ key.keyword }}
<!--          <select class="ml-1 mr-2"
                  v-model="key.match">
            <option value="2">Exact</option>
            <option value="3">Phrase</option>
            <option value="4">Broad</option>
          </select>-->
          <a href="#"
            @click.prevent="deleteKeyword(i)"
            class="text-danger">X</a>
        </div>

        <span class="text-danger" v-if="form.keywords.length < 5"> ({{ 5 - form.keywords.length }} more required)</span>
      </div>
    </div>

    <div class="form-group">
      <input :class="['form-control', {'is-invalid': errorFor('name')}]"
            :disabled="loading"
            ref="keywordInput"
            @keyup="keywordKeyupEvent($event)"
            placeholder="Enter keyword phrase and press enter"
            type="text">
        <small id="passwordHelpBlock" class="form-text text-muted my-2">Choose keywords from recommendations or enter your own.</small>
      <v-errors :errors="errorFor('name')" />
    </div>

    <div class="row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-success"
                @click="$refs.googleKeywordModal.show(form.ad_account_id, form.keywords)">Recommend more keywords from Google based on current keywords</button>
      </div>
    </div>
  </div>

  <div class="row mb-2 mt-3">
    <div class="col-12 keyword-tips">
      <ul>
        <li>It is often a good choice to add “amazon” and/or “on amazon” to keywords. For example: “Yoga mat on amazon” or “Garlic press amazon”</li>
        <li>If you want, we can do it for you while keeping the original keywords.</li>
        <li>We would only duplicate and add keywords if the phrase “amazon” or “on amazon” doesn’t already exist in them.</li>
      </ul>
    </div>
    <div class="col-12 col-md-6">
      <button type="button" class="btn btn-md btn-success w-100" @click="duplicateAmazon()">Duplicate & Add “amazon” to all keywords</button>
    </div>
    <div class="col-12 col-md-6">
      <button type="button" class="btn btn-md btn-primary w-100" @click="duplicateOnAmazon()">Duplicate & Add “on amazon” to all keywords</button>
    </div>
  </div>


  <div class="row keyword-tips mt-5">
    <h3 class="mb-3">How to choose good keywords?</h3>
    <ul>
      <li>DO Choose keywords that your customers are likely to use when searching for your product</li>
      <li>DON’T Choose keywords that are too generic. The more specific you are the better the conversion. E.g. use “travel yoga mat” not “yoga”</li>
      <li>DO Use keywords you know are converting from your Amazon advertising campaigns</li>
      <li>DON’T Worry about capitalization</li>
    </ul>
  </div>


  <google-keyword-modal ref="googleKeywordModal"
                        @selected="setNewKeywords" />
</template>

<script>
import GoogleKeywordModal from "@/views/create_campaign/google_wizard/GoogleKeywordModal";
export default {
  props: ['formData', 'errors'],
  components: {GoogleKeywordModal},
  data() {
    return {
      adAccounts: [],
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
    this.checkAmazonSearchTerm();
  },
  beforeUpdate() {
    this.form = this.formData;
    // this.checkAmazonSearchTerm();
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    /*formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }*/
  },
  methods: {
    checkAmazonSearchTerm() {
      this.form.search_term.forEach(term => {
        if (term !== undefined && term !== '' && !this.form.keywords.find(k => k.keyword.includes(term.toLowerCase())))
          this.form.keywords.push({
            keyword: term.toLowerCase(),
            match: '2'
          });
      })
    },
    deleteKeyword(index) {
      this.form.keywords.splice(index, 1);
      // this.form.keywords.splice(this.form.keywords.findIndex(v => v === keyword), 1);
    },

    duplicateAmazon() { 
      this.form.keywords.map(keyword => {
        if (!keyword.keyword.includes('amazon') && !this.form.keywords.find(k => k.keyword.includes(keyword.keyword + ' amazon'))) {
          this.form.keywords.push({
            keyword: keyword.keyword + ' amazon',
            match: '2'
          })
        }
      });
    },

    duplicateOnAmazon() {
      this.form.keywords.map(keyword => {
        if (!keyword.keyword.includes('amazon') && !keyword.keyword.includes('on amazon') &&
          !this.form.keywords.find(k => k.keyword.includes(keyword.keyword + ' on amazon'))) {
          this.form.keywords.push({
            keyword: keyword.keyword + ' on amazon',
            match: '2'
          });
        }
      });
    },

    setNewKeywords(keywords) {
      keywords.forEach(key => {
        if (!this.form.keywords.find(k => k.keyword.includes(key))) {
          this.form.keywords.push({
            keyword: key,
            match: '2'
          })
        }
      })
    },
    keywordKeyupEvent(event) {
      if (event.key === ',' || event.key === 'Enter') {
        let keyword = event.target.value.replace(',', '');
        if (keyword.length) {
          if (!this.form.keywords.find(k => k.keyword.includes(keyword.toLowerCase())))
            this.form.keywords.push({
              keyword: keyword.toLowerCase(),
              match: '2'
            });
        }
        this.$refs.keywordInput.value = '';
      }
    }
  }
}
</script>

<style>
.keyword-tips {
  padding: 0px 15px;
}

.row.keyword-tips ul {
    display: block;
    width: 100%;
    list-style: none;
    padding-left: 0;
}
.keyword-tips h3 {
    font-size: 18px;
    color: #313131;
}
.keyword-tips ul li {
    color: #313131ad;
    margin-bottom: 12px;
    line-height: 20px;
}

</style>