<template>
  <div class="modal fade" id="amazon-product-details-modal" tabindex="-1" role="dialog" aria-labelledby="basicDemoTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="modal-body" v-if="amazonProduct">
            <div class="row">
              <div class="col-6">
                <b class="text-black">Amazon Domain</b> <br>
                amazon.com
              </div>
              <div class="col-6">
                <b class="text-black">Marketplace</b> <br>
                United State
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <b class="text-black">Amazon Product ASIN</b> <br>
                {{ amazonProduct.asin }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <b class="text-black">Final URL</b> <br>
                <a class="text-info"
                   :href="amazonProduct.url"
                   target="_blank">{{ amazonProduct.url }}</a>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <b class="text-black">Product Title</b> <br>
                {{ amazonProduct.title }} <br>

                <div class="row">
                  <div class="col-12 text-center">
                    <img class="mt-2"
                         :src="amazonProduct.main_image"
                         style="max-height: 200px">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <b class="text-black">Price</b> <br>
                {{ amazonProduct.price.symbol+amazonProduct.price.current_price }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <b class="text-black">Categories</b> <br>
                <ul class="list-group mt-2">
                  <li class="list-group-item p-2"
                      v-for="category in amazonProduct.categories"
                      :key="'ca_'+category.category">
                    <a class="text-info"
                       :href="category.url"
                       target="_blank">{{ category.category }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <b class="text-black">Feature Bullet List</b> <br>
                <ul class="pl-4 mt-2">
                  <template v-for="(bullet, i) in amazonProduct.feature_bullets" :key="'bullet_'+i">
                    <li v-if="bullet != ' '">
                      {{ bullet }}
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="row mt-2" v-if="amazonProduct.variants.length">
              <div class="col-12">
                <b class="text-black">Product Variants</b> <br>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-2"
                      v-for="(variant, i) in amazonProduct.variants"
                      :key="'variant_'+i">
                    <a class="text-info"
                       :href="variant.link"
                       target="_blank">{{ variant.asin }}</a> {{ variant.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null
    }
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('amazon-product-details-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>